import React from "react";
import "./Footer.css";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="container grid footer-grid">
        <div className="logo-col">
        <a href="#" className="footer-logo">
            <img src="./logo/M-sm1.svg" alt="logo" />
          </a>
          {/* <p className="punch">Feed Poor, Kill Hunger !</p> */}
        </div>

        <div className="hitwebcounter">
          {/* <div className="subscribe">
            <p className="sub-head">Subscribe now!</p>
            <input
              type="email"
              placeholder="Email Address"
              className="sub-input"
            ></input>
            <button className="sub-btn">
              <b> &raquo;</b>{" "}
            </button>
          </div> */}
          <h3 className="visitors">Visitors:</h3>
          <a className="disabledbutton" href="https://www.hitwebcounter.com" target="_blank">
          <img src="https://hitwebcounter.com/counter/counter.php?page=7965507&style=0003&nbdigits=9&type=ip&initCount=0" title="Free Counter" alt="web counter" border="0"/></a>
         
         <h3 className="nftcalendar">As seen on:</h3>
            <li>
              <a target="_blank" href="https://nftcalendar.io/">
                {" "}
                <img
                  className="nftcalender-logo"
                  alt="nftcalender-logo"
                  src="./black_and_white_transparent_version.svg"
                ></img>
              </a>
            </li>
        </div>

        <div className="contact">
          <h3>Reach us at:</h3>
          <ul className="social-links">
            <li>
              <a target="_blank" href="https://opensea.io/collection/mutantpotato">
                {" "}
                <img
                  className="social-logo"
                  alt="social-logo"
                  src="./social-logo/opensea.svg"
                ></img>
                {/* <i className="fa fa-instagram" /> */}
              </a>
            </li>
            <li>
              <a target="_blank" href="https://twitter.com/mutantpotato_">
                {" "}
                <img
                  className="social-logo"
                  alt="social-logo"
                  src="./social-logo/twitter.png"
                ></img>
                {/* <i className="fa fa-twitter-square" /> */}
              </a>
            </li>
            <li>
              <a target="_blank" href="https://www.instagram.com/mutantpotato_/">
                {" "}
                <img
                  className="social-logo"
                  alt="social-logo"
                  src="./social-logo/instagram.svg"
                ></img>
                {/* <i className="fa fa-facebook-square" /> */}
              </a>
            </li>
            <li>
              <a target="_blank" href="https://discord.com/invite/xuNb7GzEbg">
                {" "}
                <img
                  className="social-logo"
                  alt="social-logo"
                  src="./social-logo/discord.png"
                ></img>
              </a>
            </li>
            <li>
              <a target="_blank" href="mailto:contact@mutantpotato.com">
                {" "}
                <img
                  className="social-logo"
                  alt="social-logo"
                  src="./social-logo/mail.png"
                ></img>
              </a>
            </li>
          </ul>
          <div className="copyright">
            <p>Copyright &copy; 2022 by MutantPotato Inc.</p>
          </div>
          {/* <ul className="contact-us">
            <li>
              <a href="mailto:contact@mutantpotato.com">Email Us!</a>
            </li>
            <li>
              <a href="#">Terms and Conditions</a>
            </li>
          </ul> */}
        </div>
      </div>
    </footer>
  );
};

export default Footer;
