import React from "react";
import "./Navbar.css";

const Navbar = () => {
  return (
    <div className="nav-container">
      <nav className="s-container navbar">
        <div className="brand">
          <img className="logo" alt="" src="./logo/M-sm.png" />
        </div>
        <ul className="links">
          <a className="nav-link " href="#scrollspyCollection">
            Collections
          </a>
          <a className="nav-link" href="#scrollspyWhyPotato">
            Why Potato?
          </a>
          <a className="nav-link" href="#scrollspyRoadmap">
            Roadmap
          </a>
          <a className="nav-link" href="#scrollspyTeam">
            Crew
          </a>
          <a
            target="_blank" rel="noreferrer" href="https://opensea.io/collection/mutantpotato"
            className="link buy-now"
          >
            Join Us!
          </a>
        </ul>
      </nav>
    </div>
  );
};

export default Navbar;
