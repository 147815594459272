import React from "react";
import "./MainContent.css";
import $ from "jquery";

const MainContent = () => {
  return (
    <>
      <section className="hero">
        <div className="s-container grid grid-col2">
          <div className="text-box">
            <div className="hero-head">
              <h1 className="heading-primary">Feed the poor</h1>
              <h2 className="heading-secondary welcomeTitle">
                Through NFTs, Community, and Art!
              </h2>
            </div>
            <p className="hero-desc">
              A community using NFTs to raise funds for feeding the
              underprivileged and ending hunger. If you're a potato lover,
              artist, entrepreneur, or inventor, we'd love to have you in our
              mutant farmland. All you have to do is purchase our NFTs and{" "}
              <a
                target="_blank"
                rel="noreferrer"
                href="https://discord.com/invite/xuNb7GzEbg"
              >
                join our Discord
              </a>{" "}
              to introduce yourself. If you're new to NFTs, we'd love to hear
              from you, and we'll gladly assist you in starting up.
            </p>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://opensea.io/collection/mutantpotato"
              className="link buy-now"
            >
              GET YOUR MUTANT NOW
            </a>
          </div>
          <div className="img-box">
            <img src="./logo/M-sq.png" alt="logo"></img>
          </div>
        </div>
      </section>

      <div className="s-container collection-box" id="scrollspyCollection">
        <div className="left-collections">
          <div className="collection-grid2">
            <div className="collection-item">
              <img
                data-src="./collectionimg1.png"
                alt=""
                className="full-image lazy entered loaded imgborder"
                data-ll-status="loaded"
                src="./collectionimg1.png"
              />
            </div>
            <div className="collection-item">
              <img
                data-src="./collectionimg2.png"
                alt=""
                className="full-image lazy entered loaded imgborder"
                data-ll-status="loaded"
                src="./collectionimg2.png"
              />
            </div>
          </div>
        </div>
        <div className="welcomewidth text-color">
          <h2 className="welcomeTitle heading-secondary">
            WELCOME TO THE MUTANT POTATO CLUB
          </h2>
          <div className="collection-desc">
            <p>
              MPC is a collection of 10,000 Mutated Potato NFTs, which are
              one-of-a-kind digital collectibles that mutate on the blockchain.
            </p>
            <p>
              Your Mutant Potato mutates, granting you entry to exclusive
              benefits such as THE MUTANT KITCHEN, a communal Potato mashing
              space.
            </p>
            <p>
              The mutant owners can unlock additional features and bonuses by
              activating the roadmap and helping our mutant community grow.
            </p>
          </div>
        </div>
        <div className="right-collections">
          <div className="collection-grid2">
            <div className="collection-item">
              <img
                data-src="./collectionimg3.png"
                alt=""
                className="full-image lazy entered loaded imgborder"
                data-ll-status="loaded"
                src="./collectionimg3.png"
              />
            </div>
            <div className="collection-item">
              <img
                data-src="./collectionimg4.png"
                alt=""
                className="full-image lazy entered loaded imgborder"
                data-ll-status="loaded"
                src="./collectionimg4.png"
              />
            </div>
          </div>
        </div>
      </div>

      <section className="why" id="scrollspyWhyPotato">
        <div className="s-container">
          <h3 className="heading-tertiary">Why Potato</h3>
          <h4 className="subheading welcomeTitle">
            Potatoes have undoubtedly ruled our hearts since the time
            immemorial!
          </h4>
          <div className="why-desc">
            <p>
              Mutant Potato is an artwork inspired by the discovery of a mutant
              potato in Caen, France. Potatoes invade French woman's home in her
              absence during coronavirus lockdown. In our metaverse, this
              project has been developed as a source of inspiration for cooking
              some mutated potatoes.
            </p>
            <p>
              The fundamental goal of our project would be to feed
              underprivileged people and eradicate starvation in the actual
              world by cooking mutant potatoes in our metaverse. If you're
              looking to mash some mutated potatoes, you've arrived to the right
              spot. Purchase mutant potatoes to support in the feeding of the
              needy, the eradication of hunger, and the accomplishment of our
              beautiful mission in accordance with the roadmap.
            </p>
            <p>
              All it takes is to purchase our NFTs and{" "}
              <a
                target="_blank"
                rel="noreferrer"
                href="https://discord.com/invite/xuNb7GzEbg"
              >
                join our Discord
              </a>{" "}
              and say hi.
            </p>
          </div>
        </div>
      </section>

      <div className="s-container roadmap" id="scrollspyRoadmap">
        <div className="roadmap-textbox">
          <h2 className="heading-tertiary">ROADMAP</h2>
          <p className="road-desc">
            Mash as much as you can so that we can all fight poverty. We've
            outlined a set of goals for us. Once we've achieved a predetermined
            sell through %, we'll commence on accomplishing the stated mission.
          </p>
        </div>
        <div className="roadmap-goalbox">
          <div className="FixedHeightContainer">
            <div className="Content">
              <div className="ag-timeline-block">
                <div className="ag-timeline_title-box">
                  <div className="ag-timeline_tagline">Roadmap</div>
                  <div className="ag-timeline_title">
                    Join us on our journey.
                  </div>
                </div>
                <section className="ag-section">
                  <div className="ag-format-container">
                    <div className="js-timeline ag-timeline">
                      <div className="js-timeline_line ag-timeline_line">
                        <div className="js-timeline_line-progress ag-timeline_line-progress"></div>
                      </div>
                      <div className="ag-timeline_list">

                      <div className="js-timeline_item ag-timeline_item">
                          <div className="ag-timeline-card_box">
                            <div className="js-timeline-card_point-box ag-timeline-card_point-box">
                              <div className="ag-timeline-card_point">10%</div>
                            </div>
                          </div>
                          <div className="ag-timeline-card_item">
                            <div className="ag-timeline-card_inner">
                              <div className="ag-timeline-card_info">
                                <div className="ag-timeline-card_title">
                                  Milepost 1
                                </div>
                                <div className="ag-timeline-card_desc">
                                  A maximum of 5,000 unique Mutant Potato will
                                  mutate. A portion of the profits will be donated to Ukraine.
                                </div>
                              </div>
                            </div>
                            <div className="ag-timeline-card_arrow"></div>
                          </div>
                        </div>

                        <div className="js-timeline_item ag-timeline_item">
                          <div className="ag-timeline-card_box">
                            <div className="js-timeline-card_point-box ag-timeline-card_point-box">
                              <div className="ag-timeline-card_point">20%</div>
                            </div>
                          </div>
                          <div className="ag-timeline-card_item">
                            <div className="ag-timeline-card_inner">
                              <div className="ag-timeline-card_info">
                                <div className="ag-timeline-card_title">
                                  Milepost 2
                                </div>
                                <div className="ag-timeline-card_desc">
                                  A maximum of 10,000 unique Mutant Potato will
                                  mutate.
                                </div>
                              </div>
                            </div>
                            <div className="ag-timeline-card_arrow"></div>
                          </div>
                        </div>

                        <div className="js-timeline_item ag-timeline_item">
                          <div className="ag-timeline-card_box">
                            <div className="js-timeline-card_point-box ag-timeline-card_point-box">
                              <div className="ag-timeline-card_point">40%</div>
                            </div>
                          </div>
                          <div className="ag-timeline-card_item">
                            <div className="ag-timeline-card_inner">
                              <div className="ag-timeline-card_info">
                                <div className="ag-timeline-card_title">
                                Milepost 3
                                </div>
                                <div className="ag-timeline-card_desc">
                                  MPC Merch Store gets unlocked, featuring
                                  limited Edition tees, hoodies, and other
                                  goodies.
                                </div>
                              </div>
                            </div>
                            <div className="ag-timeline-card_arrow"></div>
                          </div>
                        </div>

                        <div className="js-timeline_item ag-timeline_item">
                          <div className="ag-timeline-card_box">
                            <div className="js-timeline-card_point-box ag-timeline-card_point-box">
                              <div className="ag-timeline-card_point">60%</div>
                            </div>
                          </div>
                          <div className="ag-timeline-card_item">
                            <div className="ag-timeline-card_inner">
                              <div className="ag-timeline-card_info">
                                <div className="ag-timeline-card_title">
                                Milepost 4
                                </div>
                                <div className="ag-timeline-card_desc">
                                  MPC plans to start a YouTube channel and a
                                  MutantPotato animated series. All Mutant
                                  Owners will also have access to THE MUTANT
                                  KITCHEN(More update coming soon).
                                </div>
                              </div>
                            </div>
                            <div className="ag-timeline-card_arrow"></div>
                          </div>
                        </div>

                        <div className="js-timeline_item ag-timeline_item">
                          <div className="ag-timeline-card_box">
                            <div className="js-timeline-card_point-box ag-timeline-card_point-box">
                              <div className="ag-timeline-card_point">80%</div>
                            </div>
                          </div>
                          <div className="ag-timeline-card_item">
                            <div className="ag-timeline-card_inner">
                              <div className="ag-timeline-card_info">
                                <div className="ag-timeline-card_title">
                                Milepost 5
                                </div>
                                <div className="ag-timeline-card_desc">
                                  Donate a significant amount of money and
                                  organise various charity event to help the
                                  underprivileged people and end poverty. Every
                                  detail to be shared on our social media
                                  networks.
                                </div>
                              </div>
                            </div>
                            <div className="ag-timeline-card_arrow"></div>
                          </div>
                        </div>

                        <div className="js-timeline_item ag-timeline_item">
                          <div className="ag-timeline-card_box">
                            <div className="js-timeline-card_point-box ag-timeline-card_point-box">
                              <div className="ag-timeline-card_point">100%</div>
                            </div>
                          </div>
                          <div className="ag-timeline-card_item">
                            <div className="ag-timeline-card_inner">
                              <div className="ag-timeline-card_info">
                                <div className="ag-timeline-card_title">
                                Milepost 6
                                </div>
                                <div className="ag-timeline-card_desc">
                                  Get Mutant Potatoes their own metaverse land,
                                  and grow our own mutant farmland for all
                                  mutant owners to enjoy.
                                </div>
                              </div>
                            </div>
                            <div className="ag-timeline-card_arrow"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section className="s-container team" id="scrollspyTeam">
        <div className="team-members">
          <div className="member m1">
            <div className="member-title">Joo</div>
            <img
              className="member-img"
              src="/collectionimg5.png"
              alt="member-img1"
            ></img>
          </div>
          <div className="member m2">
            <div className="member-title">Chikoo</div>
            <img
              className="member-img"
              src="/collectionimg6.png"
              alt="member-img2"
            ></img>
          </div>
        </div>

        <div className="team-desc">
          <h2 className="team-title heading-tertiary">Crew</h2>
          <p>
            Fascinated by the mutant potato news in Caen, France. Two friends
            began putting their skills into action and generated some vivid art.
          </p>
        </div>
      </section>
    </>
  );
};

//Roadmap Scroll Section
(function () {
  $(function () {
    $(window).on("scroll", function () {
      fnOnScroll();
    });

    $(window).on("resize", function () {
      fnOnResize();
    });

    var agTimeline = $(".js-timeline"),
      agTimelineLine = $(".js-timeline_line"),
      agTimelineLineProgress = $(".js-timeline_line-progress"),
      agTimelinePoint = $(".js-timeline-card_point-box"),
      agTimelineItem = $(".js-timeline_item"),
      agOuterHeight = $(window).outerHeight(),
      agHeight = $(window).height(),
      f = -1,
      agFlag = false,
      agPosY = 0;

    function fnOnScroll() {
      agPosY = $(window).scrollTop();

      fnUpdateFrame();
    }

    function fnOnResize() {
      agPosY = $(window).scrollTop();
      agHeight = $(window).height();

      fnUpdateFrame();
    }

    function fnUpdateWindow() {
      agFlag = false;

      agTimelineLine.css({
        top:
          agTimelineItem.first().find(agTimelinePoint).offset().top -
          agTimelineItem.first().offset().top,
        bottom:
          agTimeline.offset().top +
          agTimeline.outerHeight() -
          agTimelineItem.last().find(agTimelinePoint).offset().top,
      });

      return f !== agPosY && ((f = agPosY), agHeight, fnUpdateProgress());
    }

    function fnUpdateProgress() {
      var agTop = agTimelineItem.last().find(agTimelinePoint).offset().top;

      var i = agTop + agPosY - $(window).scrollTop();
      var a =
        agTimelineLineProgress.offset().top + agPosY - $(window).scrollTop();
      var n = agPosY - a + agOuterHeight / 2;
      i <= agPosY + agOuterHeight / 2 && (n = i - a);
      agTimelineLineProgress.css({ height: n + "px" });

      agTimelineItem.each(function () {
        var agTop = $(this).find(agTimelinePoint).offset().top;

        agTop + agPosY - $(window).scrollTop() < agPosY + 0.5 * agOuterHeight
          ? $(this).addClass("js-ag-active")
          : $(this).removeClass("js-ag-active");
      });
    }

    function fnUpdateFrame() {
      agFlag || requestAnimationFrame(fnUpdateWindow);
      agFlag = true;
    }
  });
})();

export default MainContent;
